import "/home/opter/repo/code/cloudsee-web/node_modules/core-js/modules/es6.array.iterator.js";
import "/home/opter/repo/code/cloudsee-web/node_modules/core-js/modules/es6.promise.js";
import "/home/opter/repo/code/cloudsee-web/node_modules/core-js/modules/es6.object.assign.js";
import "/home/opter/repo/code/cloudsee-web/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css';
import Element from 'element-ui';
import mavonEditor from 'mavon-editor';
import 'mavon-editor/dist/css/index.css';
import Components from "./components/installer";
import Mixins from "./mixins/installer";
import Filters from "./filters/installer";

// 权限指令
import permission from "./components/Permission";
// 初始化css
import "./assets/styles/element-variables.scss";
// global css
import "./assets/styles/index.scss";

// 引入vue-quill-editor样式
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';

// 代码高亮
import VueHighlightJS from 'vue-highlightjs';
import 'highlight.js/styles/atom-one-dark.css';
import '@/assets/font_icon/iconfont.css';
import App from "./App";
import store from "./store";
import router from "./router/routers";

// icon
import "./assets/icons";
// permission control
import "./router/index";

// 百度地图
import BaiduMap from 'vue-baidu-map';
import * as constants from "./constants";
import VueClipboard from 'vue-clipboard2';
Vue.use(VueHighlightJS);
Vue.use(mavonEditor);
Vue.use(permission);
Vue.use(VueClipboard);
Vue.use(Components);
Vue.use(Mixins);
Vue.use(Filters);
Vue.use(Element, {
  // set element-ui default size
  size: Cookies.get('size') || 'small'
});
Vue.use(BaiduMap, {
  // 百度地图API KEY
  ak: constants.VUE_APP_BAIDU_MAP_API_KEY
});
Vue.config.productionTip = false;
new Vue({
  el: '#app',
  router: router,
  store: store,
  render: function render(h) {
    return h(App);
  }
});