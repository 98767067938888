import "core-js/modules/es6.regexp.to-string";
/*eslint-disable*/

/**
 * 判断是否为数字类型
 *
 * @param num
 * @return {boolean}
 */
export function isNumber(num) {
  return Object.prototype.toString.call(num) === '[object Number]';
}

/**
 * 小数点保留n位
 *
 * @param num {number}
 * @param fix {number} 保留位数
 * @return {number}
 */
export function toFixedNumber(num) {
  var fix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  var per = Math.pow(10, fix);
  return Math.round(num * per) / per;
}

/**
 * 将数字转化为固定长度的小数的字符串。
 * @param num 数字
 * @param decimalPlaces 小数位数
 * @returns {string} 转化后的结果
 */
export function formatNumber(num) {
  var decimalPlaces = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 2;
  // 检查输入是否为数字
  if (typeof num === 'number') {
    return num.toFixed(decimalPlaces);
  }

  // 尝试将输入转换为数字
  var number = parseFloat(num);

  // 检查是否成功转换为数字
  if (isNaN(number)) {
    return "0.00";
  }

  // 将数字转换为固定位数的小数
  return number.toFixed(decimalPlaces);
}

/**
 * 计算百分比
 *
 * @param numerator {number} 分子
 * @param denominator {number} 分母
 */
export function calcPercentage(numerator, denominator) {
  if (!isNumber(numerator) || !isNumber(denominator) || denominator === 0) {
    return 0;
  }
  return Math.round((numerator || 0) / denominator * 10000) / 100;
}

/**
 * 计算百分比与百分比文案
 *
 * @param numerator {number} 分子
 * @param denominator {number} 分母
 * @return {{percentageText: string, percentage: (number|*)}}
 */
export function calcPercentageAndText(numerator, denominator) {
  var percentage = calcPercentage(numerator, denominator);
  return {
    percentage: percentage,
    percentageText: percentage + '%'
  };
}