import _objectSpread from "/home/opter/repo/code/cloudsee-web/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import router from "./routers";
import store from '@/store';
import Config from '@/settings';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import { getToken } from '@/utils/auth';
import { buildMenus } from '@/api/system-setting/menu';
import { filterAsyncRouter } from '@/store/modules/permission';
NProgress.configure({
  showSpinner: false
});

// no redirect whitelist
var whiteList = ['/login'];
router.beforeEach(function (to, from, next) {
  if (to.meta.title) {
    document.title = to.meta.title + ' - ' + Config.title;
  }
  NProgress.start();
  if (getToken()) {
    // 已登录且要跳转的页面是登录页
    if (to.path === '/login') {
      next({
        path: '/'
      });
      NProgress.done();
    } else {
      // 判断当前用户是否已拉取完user_info信息
      if (store.getters.roles.length === 0) {
        // 拉取用户所有信息
        store.dispatch('GetInfo').then(function () {
          // 动态路由，拉取菜单
          loadMenus(next, to);
        }).catch(function () {
          store.dispatch('LogOut').then(function () {
            // 为了重新实例化vue-router对象 避免bug
            location.reload();
          });
        });
      } else if (store.getters.loadMenus) {
        // 登录时未拉取 菜单，在此处拉取
        // 修改成false，防止死循环
        store.dispatch('updateLoadMenus').then();
        loadMenus(next, to);
      } else {
        next();
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next();
    } else {
      next("/login?redirect=".concat(to.path)); // 否则全部重定向到登录页
      NProgress.done();
    }
  }
});
export var loadMenus = function loadMenus(next, to) {
  buildMenus().then(function (res) {
    var asyncRouter = filterAsyncRouter(res);
    asyncRouter.push({
      path: '*',
      redirect: '/404',
      hidden: true
    });
    store.dispatch('GenerateRoutes', asyncRouter).then(function () {
      // 存储路由
      router.addRoutes(asyncRouter); // 动态添加可访问路由表
      next(_objectSpread(_objectSpread({}, to), {}, {
        replace: true
      }));
    });
  });
};
router.afterEach(function () {
  NProgress.done();
});