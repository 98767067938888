import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dialogCompanyMixin from '@/mixins/dialog/company';

/**
 * 选择服务主体/处理单位组件
 */
export default {
  mixins: [dialogCompanyMixin],
  props: {
    value: {
      type: String | Number,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    // 是否展示所有节点名称，默认true
    showAllLevels: {
      type: Boolean,
      default: true
    },
    // 是否允许清除，默认true
    clearable: {
      type: Boolean,
      default: true
    },
    // 是否初始化下拉选项，默认true
    autoInitOptions: {
      type: Boolean,
      default: true
    },
    // 是否初始化选中，默认false
    autoInitValue: {
      type: Boolean,
      default: false
    },
    // 是否组件自动控制禁用状态，默认false
    autoDisabled: {
      type: Boolean,
      default: false
    },
    // 父组件决定的禁用状态，需配合 autoDisabled 使用，默认false
    selectDisabled: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function handler(value) {
        this.formData.companyId = value;
      }
    },
    companyTreeData: {
      immediate: true,
      handler: function handler(val) {
        var _this = this;
        if (this.autoInitValue) {
          if (val && val.length > 0) {
            this.initCompanyTreeId(function () {
              _this.$nextTick(function () {
                _this.onInit(_this.formData.companyId);
              });
            });
          }
        }
      }
    }
  },
  data: function data() {
    return {
      formData: {
        companyId: null
      }
    };
  },
  created: function created() {
    if (this.autoInitOptions) {
      this.initCompanyTreeData();
    }
  },
  methods: {
    onInit: function onInit(val) {
      this.$emit('input', val);
      this.$emit('init', val);
    },
    onChange: function onChange(val) {
      this.$emit('input', val);
      this.$emit('change', val);
    },
    /**
     * 获取选中节点（供外部调用）
     *
     * @param flag {boolean} 默认true
     * @return {Array|*}
     */
    getCheckedNodes: function getCheckedNodes() {
      var flag = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
      if (!this.$refs['cascader']) {
        return [];
      }
      return this.$refs['cascader'].getCheckedNodes(flag);
    }
  }
};