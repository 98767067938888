import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.regexp.to-string";
/*eslint-disable*/
// 手机校验规则
// 旧：/^[1][3,4,5,6,7,8,9][0-9]{9}$/
export var PHONE_VALID_REG = /^[1][0-9][0-9]{9}$/;
export var ID_CARD_VALID_REG = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;

/**
 * Created by PanJiaChen on 16/11/18.
 */

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUsername(str) {
  var valid_map = ['admin', 'editor'];
  return valid_map.indexOf(str.trim()) >= 0;
}

/**
 * @param {string} url
 * @returns {Boolean}
 */
export function validURL(url) {
  var reg = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/;
  return reg.test(url);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validLowerCase(str) {
  var reg = /^[a-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validUpperCase(str) {
  var reg = /^[A-Z]+$/;
  return reg.test(str);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function validAlphabets(str) {
  var reg = /^[A-Za-z]+$/;
  return reg.test(str);
}

/**
 * @param {string} email
 * @returns {Boolean}
 */
export function validEmail(email) {
  var reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test(email);
}
export function isvalidPhone(phone) {
  var reg = PHONE_VALID_REG;
  return reg.test(phone);
}

/**
 * @param {string} str
 * @returns {Boolean}
 */
export function isString(str) {
  if (typeof str === 'string' || str instanceof String) {
    return true;
  }
  return false;
}

/**
 * @param {Array} arg
 * @returns {Boolean}
 */
export function isArray(arg) {
  if (typeof Array.isArray === 'undefined') {
    return Object.prototype.toString.call(arg) === '[object Array]';
  }
  return Array.isArray(arg);
}

/**
 * 是否合法IP地址
 * @param rule
 * @param value
 * @param callback
 */
export function validateIP(rule, value, callback) {
  if (value === '' || value === undefined || value == null) {
    callback();
  } else {
    var reg = /^(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])\.(\d{1,2}|1\d\d|2[0-4]\d|25[0-5])$/;
    if (!reg.test(value) && value !== '') {
      callback(new Error('请输入正确的IP地址'));
    } else {
      callback();
    }
  }
}

/* 是否手机号码或者固话*/
export function validatePhoneTwo(rule, value, callback) {
  var reg = /^((0\d{2,3}-\d{7,8})|(1[34578]\d{9}))$/;
  if (value === '' || value === undefined || value == null) {
    callback();
  } else {
    if (!reg.test(value) && value !== '') {
      callback(new Error('请输入正确的电话号码或者固话号码'));
    } else {
      callback();
    }
  }
}

/* 是否固话*/
export function validateTelephone(rule, value, callback) {
  var reg = /0\d{2}-\d{7,8}/;
  if (value === '' || value === undefined || value == null) {
    callback();
  } else {
    if (!reg.test(value) && value !== '') {
      callback(new Error('请输入正确的固话（格式：区号+号码,如010-1234567）'));
    } else {
      callback();
    }
  }
}

/**
 * 校验手机号码（非必填）
 *
 * @param rule
 * @param value
 * @param callback
 */
export function validatePhone(rule, value, callback) {
  var reg = PHONE_VALID_REG;
  if (value === '' || value === undefined || value == null) {
    callback();
  } else {
    if (!reg.test(value) && value !== '') {
      callback(new Error('请输入正确的电话号码'));
    } else {
      callback();
    }
  }
}

/**
 * 校验手机号码（必填）
 *
 * @param rule
 * @param value
 * @param callback
 */
export function validatePhoneRequire(rule, value, callback) {
  if (!value || !PHONE_VALID_REG.test(value)) {
    callback(new Error('请输入正确的号码'));
  } else {
    callback();
  }
}
export function validateFormEmail(rule, value, callback) {
  if (!value || validEmail(value)) {
    callback();
    return;
  }
  callback(new Error('请输入正确的邮箱'));
}

/**
 * 是否身份证号码
 *
 * @param rule
 * @param value
 * @param callback
 */
export function validateIdNo(rule, value, callback) {
  if (value === '' || value === undefined || value == null) {
    callback();
  } else {
    if (!ID_CARD_VALID_REG.test(value)) {
      callback(new Error('请输入正确的身份证号码'));
    } else {
      callback();
    }
  }
}

/**
 * 身份证必填
 *
 * @param rule
 * @param value
 * @param callback
 */
export function validateIdNoRequire(rule, value, callback) {
  if ([undefined, null, ''].includes(value)) {
    callback(new Error('必填项'));
  } else {
    if (!ID_CARD_VALID_REG.test(value)) {
      callback(new Error('请输入正确的身份证号码'));
    } else {
      callback();
    }
  }
}