var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-popover",
    { attrs: { placement: "bottom-start", width: "400", trigger: "click" } },
    [
      _c("SystemIconSelector", {
        ref: "SystemIconSelector",
        on: { ready: _vm.uploadIcon, select: _vm.onSelect },
      }),
      _vm._v(" "),
      _c(
        "el-input",
        {
          staticClass: "full-width",
          attrs: {
            slot: "reference",
            placeholder: "点击选择图标",
            readonly: "",
          },
          slot: "reference",
          model: {
            value: _vm.icon.name,
            callback: function ($$v) {
              _vm.$set(_vm.icon, "name", $$v)
            },
            expression: "icon.name",
          },
        },
        [
          _vm.icon.url
            ? _c("svg-icon", {
                staticClass: "el-input__icon",
                staticStyle: { width: "16px", height: "32px" },
                attrs: { slot: "prefix", "icon-class": _vm.icon.url },
                slot: "prefix",
              })
            : _c("i", {
                staticClass: "el-icon-search el-input__icon",
                attrs: { slot: "prefix" },
                slot: "prefix",
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }