import _defineProperty from "/home/opter/repo/code/cloudsee-web/node_modules/@babel/runtime-corejs2/helpers/esm/defineProperty.js";
var _ATTENDANCE_CALENDAR_;
/*eslint-disable*/
import * as dict from "../dict";
export var ALERT_LEVEL_LABEL = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, dict.WORK_PLAN_TASK_ALERT_LEVEL_NONE, '无'), dict.WORK_PLAN_TASK_ALERT_LEVEL_GREEN, '蓝灯'), dict.WORK_PLAN_TASK_ALERT_LEVEL_YELLOW, '黄灯'), dict.WORK_PLAN_TASK_ALERT_LEVEL_RED, '红灯');
export var CYCLE_UNIT_Label = _defineProperty(_defineProperty(_defineProperty(_defineProperty({}, dict.CYCLE_UNIT_DAY, '天'), dict.CYCLE_UNIT_WEEK, '周'), dict.CYCLE_UNIT_MONTH, '月'), dict.CYCLE_UNIT_YEAR, '年');
export var WORK_ORDER_PROCESS_CODE_MAP = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, dict.WORK_ORDER_TYPE_GUARANTEE, dict.WORK_ORDER_PROCESS_CODE_GUARANTEE), dict.WORK_ORDER_TYPE_COMPLAINT, dict.WORK_ORDER_PROCESS_CODE_COMPLAINT), dict.WORK_ORDER_TYPE_CONSULTING, dict.WORK_ORDER_PROCESS_CODE_CONSULTING), dict.WORK_ORDER_TYPE_PROPOSAL, dict.WORK_ORDER_PROCESS_CODE_PROPOSAL), dict.WORK_ORDER_TYPE_PRAISE, dict.WORK_ORDER_PROCESS_CODE_PRAISE), dict.WORK_ORDER_TYPE_EXCEPTION, dict.WORK_ORDER_PROCESS_CODE_EXCEPTION), dict.WORK_ORDER_TYPE_RECTIFICATION, dict.WORK_ORDER_PROCESS_CODE_RECTIFICATION), dict.WORK_ORDER_TYPE_RESERVE, dict.WORK_ORDER_PROCESS_CODE_RESERVE), dict.WORK_ORDER_TYPE_TASK, dict.WORK_ORDER_PROCESS_CODE_TASK), dict.WORK_ORDER_TYPE_EQUIPMENT_MAINTENANCE, dict.WORK_ORDER_PROCESS_CODE_EQUIPMENT_MAINTENANCE);

/**
 * 工单模块名称转换
 */
// 名称描述
export var WORK_ORDER_LABEL_NAME_MAP = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, dict.WORK_ORDER_TYPE_GUARANTEE, '报修'), dict.WORK_ORDER_TYPE_COMPLAINT, '投诉'), dict.WORK_ORDER_TYPE_CONSULTING, '咨询'), dict.WORK_ORDER_TYPE_PROPOSAL, '建议'), dict.WORK_ORDER_TYPE_PRAISE, '表扬'), dict.WORK_ORDER_TYPE_EXCEPTION, '异常'), dict.WORK_ORDER_TYPE_RECTIFICATION, '整改'), dict.WORK_ORDER_TYPE_RESERVE, '预约'), dict.WORK_ORDER_TYPE_TASK, '任务'), dict.WORK_ORDER_TYPE_EQUIPMENT_MAINTENANCE, '维修');

/**
 * 周翻译
 */
export var WEEK_DAY_NAME_MAP_SHORT = _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty({}, dict.WEEK_DAY_1, '一'), dict.WEEK_DAY_2, '二'), dict.WEEK_DAY_3, '三'), dict.WEEK_DAY_4, '四'), dict.WEEK_DAY_5, '五'), dict.WEEK_DAY_6, '六'), dict.WEEK_DAY_7, '日');

/**
 * 考勤记录类型
 */
export var ATTENDANCE_CALENDAR_TYPE_MAP = (_ATTENDANCE_CALENDAR_ = {}, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ATTENDANCE_CALENDAR_, dict.ATTENDANCE_CALENDAR_TYPE_NORMAL, '正常'), dict.ATTENDANCE_CALENDAR_TYPE_LATE, '迟到'), dict.ATTENDANCE_CALENDAR_TYPE_LATE_SERIOUS, '严迟'), dict.ATTENDANCE_CALENDAR_TYPE_LATE_ABSENTEEISM, '旷迟'), dict.ATTENDANCE_CALENDAR_TYPE_EARLY, '早退'), dict.ATTENDANCE_CALENDAR_TYPE_ABS, '旷工'), dict.ATTENDANCE_CALENDAR_TYPE_REST, '休息'), dict.ATTENDANCE_CALENDAR_TYPE_ASK_FOR_LEAVE, '请假'), dict.ATTENDANCE_CALENDAR_TYPE_REPLACEMENT, '补卡'), dict.ATTENDANCE_CALENDAR_TYPE_GO_OUT, '外出'), _defineProperty(_defineProperty(_ATTENDANCE_CALENDAR_, dict.ATTENDANCE_CALENDAR_TYPE_BUSINESS_TRIP, '出差'), dict.ATTENDANCE_CALENDAR_TYPE_WORK_TIME, '加班'));