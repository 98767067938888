import request from '@/utils/request';
export function getMenusTree() {
  return request({
    url: 'api/menus/tree',
    method: 'get'
  });
}
export function buildMenus() {
  return request({
    url: 'api/menus/build',
    method: 'get'
  });
}
export default {
  getMenusTree: getMenusTree,
  buildMenus: buildMenus
};