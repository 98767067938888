/* eslint-disable */

// 百度地图坐标拾取系统
export var BAIDU_MAP_GET_POINT_PAGE = 'https://api.map.baidu.com/lbsapi/getpoint/index.html';

// 腾讯地图坐标拾取系统
export var QQ_MAP_GET_POINT_PAGE = 'https://lbs.qq.com/getPoint/';

// 甲方信息缓存key
export var STORAGE_KEY_PARTY_INFO = 'STORAGE_USER_PARTY';

// 系统配置缓存key
export var STORAGE_KEY_BASE_CONFIG = 'STORAGE_KEY_BASE_CONFIG';

// 401 弹窗提示次数
export var STORAGE_KEY_401_CONFIRM_TIME = 'STORAGE_401_CONFIRM_TIME';

// 计划任务预警规则最小时间区间
export var PLAN_TASK_ALERT_TIME_DURING_MIN = 0;

// 计划任务预警规则最大时间区间
export var PLAN_TASK_ALERT_TIME_DURING_MAX = 48;

// 快捷入口默认排序顺序
export var DEFAULT_QUICK_ENTRY_SORT = 2000;