/**
 * @param {string} url
 * @param {string} title
 * @param {Number} w
 * @param {Number} h
 */
export function openWindow(url, title, w, h) {
  // Fixes dual-screen position                            Most browsers       Firefox
  var dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : screen.left;
  var dualScreenTop = window.screenTop !== undefined ? window.screenTop : screen.top;
  var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
  var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;
  var left = width / 2 - w / 2 + dualScreenLeft;
  var top = height / 2 - h / 2 + dualScreenTop;
  var newWindow = window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);

  // Puts focus on the newWindow
  if (window.focus) {
    newWindow.focus();
  }
}

/**
 * 手动触发window resize
 */
export function resizeWindow() {
  setTimeout(function () {
    var event = new Event('resize');
    window.dispatchEvent(event);
  }, 30);
}

/**
 * 手动触发window resize，兼容IE
 */
export function resizeWindowCompatible() {
  setTimeout(function () {
    if (document.createEvent) {
      var event = document.createEvent('HTMLEvents');
      event.initEvent('resize', true, true);
      window.dispatchEvent(event);
    } else if (document.createEventObject) {
      window.fireEvent('onresize');
    }
  }, 30);
}