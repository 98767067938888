var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          staticClass: "full-width",
          attrs: {
            "popper-class": "popper-class-dict-select",
            multiple: _vm.multiple,
            placeholder: _vm.placeholder,
            clearable: _vm.clearable,
            options: _vm.companyListData,
            disabled: _vm.autoDisabled
              ? _vm.companyIdDisabled
              : _vm.selectDisabled,
          },
          on: { change: _vm.onChange, "remove-tag": _vm.onRemoveTag },
          model: {
            value: _vm.formData.companyId,
            callback: function ($$v) {
              _vm.$set(_vm.formData, "companyId", $$v)
            },
            expression: "formData.companyId",
          },
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(_vm.companyListData, function (item, index) {
      return _c("el-option", {
        key: index,
        attrs: {
          label: item.label,
          value: item.value,
          disabled: item.disabled,
        },
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }