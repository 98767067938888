var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "icon-body" },
    [
      _c(
        "el-input",
        {
          staticStyle: { position: "relative" },
          attrs: { clearable: "", placeholder: "输入图标名称搜索" },
          on: { clear: _vm.searchIconByName },
          nativeOn: {
            input: function ($event) {
              return _vm.searchIconByName($event)
            },
          },
          model: {
            value: _vm.name,
            callback: function ($$v) {
              _vm.name = $$v
            },
            expression: "name",
          },
        },
        [
          _c("i", {
            staticClass: "el-icon-search el-input__icon",
            attrs: { slot: "suffix" },
            slot: "suffix",
          }),
        ]
      ),
      _vm._v(" "),
      _c(
        "el-row",
        { staticClass: "icon-list" },
        _vm._l(_vm.filterList, function (item, index) {
          return _c(
            "el-col",
            {
              key: index,
              staticClass: "icon-item",
              attrs: { span: 8 },
              nativeOn: {
                click: function ($event) {
                  return _vm.onSelectIcon(item)
                },
              },
            },
            [
              _c("svg-icon", {
                staticClass: "svg",
                attrs: { "icon-class": item.url },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }