var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        visible: "",
        "append-to-body": true,
        "close-on-click-modal": false,
        "close-on-press-escape": true,
        "show-close": true,
        title: "修改密码",
        width: "440px",
      },
      on: { close: _vm.onClose },
    },
    [
      _c(
        "div",
        {
          staticClass: "mb-15",
          staticStyle: { "font-size": "12px", color: "#666" },
        },
        [_vm._v("检测到账号密码为弱密码，请修改密码，否则将无法继续使用系统。")]
      ),
      _vm._v(" "),
      _c(
        "el-form",
        {
          ref: "refForm",
          attrs: {
            size: "small",
            "label-width": "92px",
            model: _vm.formData,
            rules: _vm.formRules,
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "账号名", prop: "username" } },
                    [
                      _c("el-input", {
                        attrs: { disabled: true },
                        model: {
                          value: _vm.formData.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "username", $$v)
                          },
                          expression: "formData.username",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "旧密码", prop: "oldPass" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          "auto-complete": "new-password",
                        },
                        model: {
                          value: _vm.formData.oldPass,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "oldPass", $$v)
                          },
                          expression: "formData.oldPass",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "新密码", prop: "newPass" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          "auto-complete": "new-password",
                        },
                        model: {
                          value: _vm.formData.newPass,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "newPass", $$v)
                          },
                          expression: "formData.newPass",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "确认新密码", prop: "newPassCheck" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "password",
                          "auto-complete": "new-password",
                        },
                        model: {
                          value: _vm.formData.newPassCheck,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "newPassCheck", $$v)
                          },
                          expression: "formData.newPassCheck",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.onClose } }, [_vm._v("取消")]),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { loading: _vm.loading, type: "primary" },
              on: { click: _vm.onSubmit },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }