import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable */
/**
 * 字典单项选项组件
 */
export default {
  props: {
    value: {
      type: Number | String
    },
    // dict: Array<{ label, value }>
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      selectedValue: null
    };
  },
  watch: {
    value: {
      immediate: true,
      handler: function handler(value) {
        this.selectedValue = value;
      }
    }
  },
  methods: {
    onChange: function onChange(val) {
      this.$emit('input', val);
      this.$emit('change', val);
    }
  }
};