import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.regexp.to-string";
import axios from 'axios';
import router from '@/router/routers';
import { Notification, MessageBox } from 'element-ui';
import store from "../store";
import { getToken } from '@/utils/auth';
import Config from '@/settings';
import { STORAGE_KEY_401_CONFIRM_TIME } from '@/constants';

/**
 * 创建axios实例
 *
 * @type {AxiosInstance}
 */
var service = axios.create({
  // api 的 base_url
  baseURL: process.env.VUE_APP_BASE_API,
  // 请求超时时间
  timeout: Config.timeout
});

/**
 * request 拦截器
 */
service.interceptors.request.use(function (config) {
  if (getToken()) {
    config.headers['Authorization'] = getToken();
  }
  config.headers['Content-Type'] = 'application/json';
  return config;
}, function (error) {
  Promise.reject(error);
});

/**
 * response 拦截器
 */
service.interceptors.response.use(function (response) {
  var code = response.status;
  if (code < 200 || code > 300) {
    Notification.error({
      title: response.message
    });
    return Promise.reject('error');
  } else {
    return response.data;
  }
}, function (error) {
  var code = 0;
  try {
    code = error.response.data.status;
  } catch (e) {
    if (error.toString().indexOf('Error: timeout') !== -1) {
      Notification.error({
        title: '网络请求超时',
        duration: 5000
      });
      return Promise.reject(error);
    }
  }
  if (code) {
    if (code === 401) {
      var confirmTime = sessionStorage.getItem(STORAGE_KEY_401_CONFIRM_TIME);
      if (Number(confirmTime)) {
        return;
      }
      sessionStorage.setItem(STORAGE_KEY_401_CONFIRM_TIME, '1');
      MessageBox.confirm('登录状态已过期，您可以继续留在该页面，或者重新登录', '系统提示', {
        confirmButtonText: '重新登录',
        showCancelButton: false,
        showClose: false,
        type: 'warning'
      }).then(function () {
        store.dispatch('RemoveLoginInfo').then(function () {
          // 为了重新实例化vue-router对象 避免bug
          location.reload();
        });
      });
    } else if (code === 403) {
      router.push({
        path: '/401'
      });
    } else {
      var errorMsg = error.response.data.message;
      if (errorMsg !== undefined) {
        Notification.error({
          title: errorMsg,
          duration: 5000
        });
      }
    }
  } else {
    Notification.error({
      title: '接口请求失败',
      duration: 5000
    });
  }
  return Promise.reject(error);
});
export default service;