import "core-js/modules/es6.array.find";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * 字典下拉组件
 */
export default {
  props: {
    value: {
      type: Number | String | Array
    },
    // dict: Array<{ label, value }>
    options: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    filterNull: {
      type: Boolean,
      default: false
    }
  },
  data: function data() {
    return {
      selectedValue: null
    };
  },
  watch: {
    value: {
      immediate: true,
      handler: function handler(value) {
        this.selectedValue = value;
      }
    }
  },
  computed: {
    filterNullOptions: function filterNullOptions() {
      return this.options.filter(function (option) {
        return option.value !== 'null';
      });
    },
    popperHeight: function popperHeight() {
      if (!this.options || this.options === 0 || this.options.length > 10) {
        return '300px';
      }
      return "".concat(34 * this.options.length, "px");
    },
    popperVars: function popperVars() {
      return {
        '--popper-height-dict-select': this.popperHeight
      };
    }
  },
  methods: {
    onChange: function onChange(val) {
      this.$emit('input', val);
      this.$emit('change', val);
    },
    onRemoveTag: function onRemoveTag(val) {
      this.$emit('remove-tag', val);
    },
    getSelectedOption: function getSelectedOption() {
      var _this = this;
      var option = this.options.find(function (item) {
        return item.value === _this.selectedValue;
      });
      return option || {};
    }
  }
};