import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
/* eslint-disable */
import filters from "./index";
var install = function install(Vue) {
  Object.keys(filters).forEach(function (key) {
    Vue.filter(key, filters[key]);
  });
};
export default {
  install: install
};