import _objectSpread from "/home/opter/repo/code/cloudsee-web/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * 表单中选择图标组件
 */
export default {
  props: {
    // icon 的 url
    value: {
      type: String,
      default: null
    }
  },
  data: function data() {
    return {
      icon: {
        name: null,
        url: null
      }
    };
  },
  watch: {
    value: {
      immediate: true,
      handler: function handler() {
        var _this = this;
        this.$nextTick(function () {
          _this.uploadIcon();
        });
      }
    }
  },
  methods: {
    uploadIcon: function uploadIcon() {
      if (!this.$refs['SystemIconSelector']) {
        return;
      }
      var icon = this.$refs['SystemIconSelector'].findIconByUrl(this.value);
      this.icon = _objectSpread(_objectSpread({}, this.icon), icon);
    },
    onSelect: function onSelect(icon) {
      this.$emit('input', icon.url);
    }
  }
};