var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-select",
    _vm._g(
      _vm._b(
        {
          staticClass: "full-width",
          style: _vm.popperVars,
          attrs: {
            "popper-class": "popper-class-dict-select",
            clearable: _vm.clearable,
            filterable: _vm.filterable,
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            multiple: _vm.multiple,
          },
          on: { change: _vm.onChange, "remove-tag": _vm.onRemoveTag },
          model: {
            value: _vm.selectedValue,
            callback: function ($$v) {
              _vm.selectedValue = $$v
            },
            expression: "selectedValue",
          },
        },
        "el-select",
        _vm.$attrs,
        false
      ),
      _vm.$listeners
    ),
    _vm._l(
      _vm.filterNull ? _vm.filterNullOptions : _vm.options,
      function (item, index) {
        return _c("el-option", {
          key: index,
          attrs: {
            label: item.label,
            value: item.value,
            disabled: item.disabled,
          },
        })
      }
    ),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }