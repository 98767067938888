/* eslint-disable */
import DictRadioGroup from "./DictRadioGroup";
import DictSelect from "./DictSelect";
import AreaCascader from "../views/components/AreaCascader";
import AreaSelector from "../views/components/AreaSelector";
import CompanyCascader from "../views/components/CompanyCascader";
import CompanySelector from "../views/components/CompanySelector";
import DeptCascader from "../views/components/DeptCascader";
import SystemIconSelector from "../views/components/SystemIconSelector";
import FormItemSystemIconSelector from "../views/components/FormItemSystemIconSelector";
import FormItemTextareaEditor from "../views/components/FormItemTextareaEditor";
import WorkOrderTypeCascader from "../views/components/WorkOrderTypeCascader";
import WorkOrderTypeSelector from "../views/components/WorkOrderTypeSelector";
import WorkOrderResourceTreeCascader from "../views/components/WorkOrderResourceTreeCascader";
import AreaTransfer from "@/views/personnel-manager/user/components/AreaTransfer/AreaTransfer.vue";
var install = function install(Vue) {
  Vue.component('DictRadioGroup', DictRadioGroup);
  Vue.component('DictSelect', DictSelect);
  Vue.component('AreaCascader', AreaCascader);
  Vue.component('AreaSelector', AreaSelector);
  Vue.component('AreaTransfer', AreaTransfer);
  Vue.component('CompanyCascader', CompanyCascader);
  Vue.component('CompanySelector', CompanySelector);
  Vue.component('DeptCascader', DeptCascader);
  Vue.component('SystemIconSelector', SystemIconSelector);
  Vue.component('FormItemSystemIconSelector', FormItemSystemIconSelector);
  Vue.component('FormItemTextareaEditor', FormItemTextareaEditor);
  Vue.component('WorkOrderTypeCascader', WorkOrderTypeCascader);
  Vue.component('WorkOrderTypeSelector', WorkOrderTypeSelector);
  Vue.component('WorkOrderResourceTreeCascader', WorkOrderResourceTreeCascader);
};
export default {
  install: install
};