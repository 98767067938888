var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-radio-group",
    _vm._b(
      {
        on: { change: _vm.onChange },
        model: {
          value: _vm.selectedValue,
          callback: function ($$v) {
            _vm.selectedValue = $$v
          },
          expression: "selectedValue",
        },
      },
      "el-radio-group",
      _vm.$attrs,
      false
    ),
    _vm._l(_vm.options, function (item, index) {
      return _c("el-radio", { key: index, attrs: { label: item.value } }, [
        _vm._v(_vm._s(item.label)),
      ])
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }