import "core-js/modules/web.dom.iterable";
/*eslint-disable*/

/**
 * 树节点格式化
 *
 * @param data {Array}
 * @return {Array}
 */
export function formatTreeNode() {
  var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  if (!data || data.length === 0) {
    return data;
  }
  var _formatNode = function formatNode() {
    var list = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    list.forEach(function (item) {
      if (item.children && item.children.length > 0) {
        _formatNode(item.children);
      } else {
        item.children = null;
      }
    });
  };
  _formatNode(data);
  return data;
}