var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-cascader",
    _vm._b(
      {
        ref: "cascader",
        staticClass: "full-width",
        attrs: {
          options: _vm.treeData,
          props: _vm.treeProps,
          clearable: _vm.clearable,
          filterable: _vm.filterable,
          size: "mini",
        },
        on: { change: _vm.onChange },
        model: {
          value: _vm.formData.selectedValue,
          callback: function ($$v) {
            _vm.$set(_vm.formData, "selectedValue", $$v)
          },
          expression: "formData.selectedValue",
        },
      },
      "el-cascader",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }