var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DictSelect", {
    attrs: {
      placeholder: _vm.placeholder,
      clearable: _vm.clearable,
      filterable: _vm.filterable,
      disabled: _vm.disabled,
      options: _vm.areaDict,
    },
    on: { change: _vm.onChange },
    model: {
      value: _vm.formData.areaId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "areaId", $$v)
      },
      expression: "formData.areaId",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }