/* eslint-disable */
import Dict from "./dict";
var install = function install(Vue) {
  Vue.mixin({
    data: function data() {
      return {
        dict: {
          label: {}
        }
      };
    },
    created: function created() {
      var _this = this;
      if (this.$options.dicts instanceof Array) {
        var dict = new Dict(this.dict);
        console.log(this.$options, "this.$options");
        dict.initOptions(this.$options.dicts);
        dict.loadOptions(this.$options.dicts).then(function () {
          _this.$nextTick(function () {
            _this.$emit('dictReady');
          });
        });
      }
    }
  });
};
export default {
  install: install
};