import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//

import dialogResourceMixin from '@/mixins/dialog/resource';

/**
 * 选择服务项目树下拉组件
 * tips：无此数据结构，未使用
 */
export default {
  mixins: [dialogResourceMixin],
  props: {
    value: {
      type: String | Number,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    // 是否允许清除，默认true
    clearable: {
      type: Boolean,
      default: true
    },
    filterable: {
      type: Boolean,
      default: true
    },
    // 是否组件自动控制禁用状态，默认false
    disabled: {
      type: Boolean,
      default: false
    },
    // 是否初始化下拉选项，默认true
    autoInitOptions: {
      type: Boolean,
      default: true
    },
    // 是否初始化选中，默认false
    autoInitValue: {
      type: Boolean,
      default: false
    },
    companyId: {
      type: String,
      default: null
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function handler(value) {
        this.formData.areaId = value;
      }
    },
    // 根据 autoInitValue 判断是否需要默认选中
    areaDict: {
      immediate: true,
      handler: function handler(val) {
        if (this.autoInitValue) {
          if (val && val.length > 0) {
            this.formData.areaId = val[0].value;
            this.onInit(val[0].value);
          }
        }
      }
    },
    companyId: {
      handler: function handler(val) {
        var _this = this;
        this.$nextTick(function () {
          _this.onChange(null);
          _this.initDialogAreaDict({
            companyId: val
          });
        });
      }
    }
  },
  data: function data() {
    return {
      formData: {
        areaId: null
      }
    };
  },
  created: function created() {
    if (this.autoInitOptions) {
      this.initDialogAreaDict({
        companyId: this.companyId
      });
    }
  },
  methods: {
    onInit: function onInit(val) {
      this.$emit('input', val);
      this.$emit('init', val);
    },
    onChange: function onChange(val) {
      this.$emit('input', val);
      this.$emit('change', val);
    }
  }
};