/*eslint-disable*/
import store from '@/store';
var _document = document,
  body = _document.body;
// refer to Bootstrap's responsive design
var WIDTH = 992;
export default {
  mounted: function mounted() {
    var isMobile = this.judgeIsMobile();
    if (isMobile) {
      store.dispatch('app/toggleDevice', 'mobile');
      store.dispatch('app/closeSideBar', {
        withoutAnimation: true
      });
    }
  },
  methods: {
    // use $_ for mixins properties
    // https://vuejs.org/v2/style-guide/index.html#Private-property-names-essential
    judgeIsMobile: function judgeIsMobile() {
      var rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    }
  }
};