var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "full-width" },
    [
      _c(
        "el-dialog",
        {
          attrs: {
            visible: "",
            "close-on-click-modal": false,
            title: _vm.title,
            width: "1800",
            top: "50px",
          },
          on: { close: _vm.onBeforeClose },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 8 } },
                [
                  _c("div", { staticClass: "title_label" }, [
                    _vm._v("选择项目"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { staticClass: "mb-10" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: { clearable: "", placeholder: "项目名称" },
                            model: {
                              value: _vm.areaName,
                              callback: function ($$v) {
                                _vm.areaName = $$v
                              },
                              expression: "areaName",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success", size: "mini" },
                              on: { click: _vm.searchAreaList },
                            },
                            [_vm._v("搜索")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.paginatedAreaList,
                        size: "small",
                        border: "",
                        height: _vm.tableHeight,
                      },
                      on: { "selection-change": _vm.onSelectionChange },
                    },
                    [
                      _c("el-table-column", {
                        attrs: {
                          type: "selection",
                          width: "42",
                          fixed: "left",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          type: "index",
                          label: "序号",
                          align: "center",
                        },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          prop: "areaName",
                          label: "服务项目",
                          "show-tooltip-when-overflow": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("el-pagination", {
                    attrs: {
                      "current-page": _vm.currentPage,
                      "page-size": 100,
                      layout: "total, prev, pager, next",
                      total: this.total,
                    },
                    on: {
                      "size-change": _vm.handleSizeChange,
                      "current-change": _vm.handleCurrentChange,
                      "update:currentPage": function ($event) {
                        _vm.currentPage = $event
                      },
                      "update:current-page": function ($event) {
                        _vm.currentPage = $event
                      },
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c("el-col", { staticClass: "full-height", attrs: { span: 1 } }, [
                _c(
                  "div",
                  { staticClass: "button_container" },
                  [
                    _c("el-button", {
                      attrs: {
                        type: "primary",
                        size: "mini",
                        icon: "el-icon-d-arrow-right",
                      },
                      on: { click: _vm.onAddRows },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "el-col",
                { attrs: { span: 15 } },
                [
                  _c("div", { staticClass: "title_label" }, [
                    _vm._v("已选择项目"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "el-row",
                    { staticClass: "mb-10" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 24 } },
                        [
                          _c("el-input", {
                            staticClass: "filter-item",
                            attrs: { clearable: "", placeholder: "项目名称" },
                            model: {
                              value: _vm.areaNameTemp,
                              callback: function ($$v) {
                                _vm.areaNameTemp = $$v
                              },
                              expression: "areaNameTemp",
                            },
                          }),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "success", size: "mini" },
                              on: { click: _vm.searchAreaTempList },
                            },
                            [_vm._v("搜索")]
                          ),
                          _vm._v(" "),
                          _c(
                            "el-button",
                            {
                              attrs: { type: "danger", size: "mini" },
                              on: { click: _vm.onDeleteSelectArea },
                            },
                            [_vm._v("删除已选项目")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-table",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: false,
                          expression: "false",
                        },
                      ],
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.areaListTemp,
                        size: "small",
                        border: "",
                        height: _vm.tableHeight,
                      },
                    },
                    [
                      _c("el-table-column", {
                        attrs: { type: "index", label: "序号" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: { prop: "areaName", label: "服务项目" },
                      }),
                      _vm._v(" "),
                      _c("el-table-column", {
                        attrs: {
                          width: "50",
                          label: "操作",
                          align: "center",
                          fixed: "right",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function (scope) {
                              return [
                                _c(
                                  "el-link",
                                  {
                                    attrs: { type: "primary" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onDeleteArea(scope.$index)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onBeforeClose } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onSubmit } },
                [_vm._v("确定")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }