var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("EditorWrap", {
    ref: "infoEditor",
    staticStyle: { width: "100%" },
    attrs: { config: _vm.editorConfig },
    on: { ready: _vm.ready },
    model: {
      value: _vm.content,
      callback: function ($$v) {
        _vm.content = $$v
      },
      expression: "content",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }