import "core-js/modules/es6.array.find";
import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dialogCompanyMixin from '@/mixins/dialog/company';

/**
 * 选择处理单位下拉组件
 */
export default {
  mixins: [dialogCompanyMixin],
  props: {
    value: {
      type: String | Number,
      default: null
    },
    placeholder: {
      type: String,
      default: '服务主体'
    },
    // 是否允许清除，默认true
    clearable: {
      type: Boolean,
      default: true
    },
    // 是否允许过滤，默认true
    filterable: {
      type: Boolean,
      default: true
    },
    // 是否初始化下拉选项，默认true
    autoInitOptions: {
      type: Boolean,
      default: true
    },
    // 是否初始化选中，默认false
    autoInitValue: {
      type: Boolean,
      default: false
    },
    // 是否组件自动控制禁用状态，默认false
    autoDisabled: {
      type: Boolean,
      default: false
    },
    // 父组件决定的禁用状态，需配合 autoDisabled 使用，默认false
    selectDisabled: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler: function handler(value) {
        this.formData.companyId = value;
      }
    },
    companyListData: {
      immediate: true,
      handler: function handler(val) {
        var _this = this;
        if (this.autoInitValue && val.length > 0) {
          this.initCompanyListId(function () {
            _this.$nextTick(function () {
              _this.onInit(val[0].id);
            });
          });
        }
      }
    }
  },
  data: function data() {
    return {
      formData: {
        companyId: null
      }
    };
  },
  created: function created() {
    if (this.autoInitOptions) {
      this.initCompanyListData();
    }
  },
  methods: {
    onInit: function onInit(val) {
      this.$emit('input', val);
      this.$emit('init', val);
    },
    onChange: function onChange(val) {
      this.$emit('input', val);
      this.$emit('change', val);
    },
    onRemoveTag: function onRemoveTag(val) {
      this.$emit('remove-tag', val);
    },
    /**
     * 获取选中的选项
     */
    getSelectedOption: function getSelectedOption() {
      var _this2 = this;
      var selectedOption = this.companyListData.find(function (item) {
        return item.value === _this2.formData.companyId;
      });
      return selectedOption || {};
    }
  }
};