import _objectSpread from "/home/opter/repo/code/cloudsee-web/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import Logo from "./Logo";
import SidebarItem from "./SidebarItem";
import _variables from '@/assets/styles/variables.scss';

/* eslint-disable */
export default {
  components: {
    SidebarItem: SidebarItem,
    Logo: Logo
  },
  data: function data() {
    return {
      logo: '',
      title: ''
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(['permission_routers', 'sidebar', 'user', 'settings'])), {}, {
    activeMenu: function activeMenu() {
      var route = this.$route;
      var meta = route.meta,
        path = route.path;
      if (meta.activeMenu) {
        return meta.activeMenu;
      }
      return path;
    },
    showLogo: function showLogo() {
      var _ref = this.$store.state.settings || {},
        sidebarLogo = _ref.sidebarLogo;
      return sidebarLogo;
    },
    variables: function variables() {
      return _variables;
    },
    isCollapse: function isCollapse() {
      return !this.sidebar.opened;
    },
    partyTitle: function partyTitle() {
      var _ref2 = this.$store.state.user || {},
        party = _ref2.party;
      return party ? party.pcTitle || '云见-智慧后勤管理平台' : '云见-智慧后勤管理平台';
    },
    partyLogo: function partyLogo() {
      var _ref3 = this.$store.state.user || {},
        party = _ref3.party;
      return party ? party.logo : null;
    }
  })
};