import "core-js/modules/es6.number.constructor";
//
//
//
//
//
//
//
//
//
//

import EditorWrap from 'vue-ueditor-wrap';
var MAX_MUM_WORDS = 10240;
export default {
  components: {
    EditorWrap: EditorWrap
  },
  props: {
    // 必须
    value: null,
    // 最大字数限制
    maximumWords: {
      type: Number,
      default: MAX_MUM_WORDS
    },
    // 是否只读
    readonly: {
      type: Boolean,
      default: false
    },
    // 编辑器高度
    height: {
      type: Number,
      default: 200
    }
  },
  data: function data() {
    return {
      $editor: null,
      content: null
    };
  },
  computed: {
    editorConfig: function editorConfig() {
      return {
        // 编辑器不自动被内容撑高
        autoHeightEnabled: false,
        // 初始容器高度
        initialFrameHeight: this.height,
        // 初始容器宽度
        initialFrameWidth: '100%',
        UEDITOR_HOME_URL: '/UEditor/',
        serverUrl: '',
        // 允许的最大字符数
        maximumWords: this.maximumWords,
        // 是否只读
        readonly: this.readonly
      };
    }
  },
  watch: {
    value: {
      handler: function handler(newVal) {
        this.content = newVal;
      },
      immediate: true
    },
    content: {
      handler: function handler(newVal) {
        this.$emit('input', newVal);
      }
    }
  },
  methods: {
    /**
     * 初始化编辑器
     */
    initEditor: function initEditor() {
      window.UE.registerUI('yshop', function (editor, uiName) {}, 37);
    },
    /**
     * 保存vue-ueditor-wrap实例
     */
    ready: function ready(instance) {
      this.$editor = instance;
    },
    /**
     * 无用
     */
    initContent: function initContent(value) {
      if (!this.$editor) {
        return;
      }
      this.$editor.execCommand('inserthtml', value);
    },
    /**
     * 校验文本长度
     */
    validateLength: function validateLength() {
      var $editor = this.$refs['infoEditor'];
      var valid = $editor.editor.body.innerText.length <= this.maximumWords;
      return {
        valid: valid,
        msg: valid ? '' : "\u6700\u5927\u53EF\u8F93\u5165\u5185\u5BB9\u9650\u5236\u4E3A".concat(this.maximumWords, "\u4E2A\u5B57\u7B26")
      };
    }
  }
};