import "core-js/modules/es6.number.constructor";
/*eslint-disable*/
import { toFixedNumber, formatNumber } from '@/utils/number';
import { parseTime } from '@/utils/index';
export default {
  /**
   * 保留两位小数
   *
   * @param value
   * @return {*}
   */
  fixedNumber: function fixedNumber(value) {
    return toFixedNumber(value);
  },
  /** 格式胡为，固定2为小数的字符串 */fixedNumber2Dec: function fixedNumber2Dec(value) {
    return formatNumber(value, 2);
  },
  /**
   * 将后台保存的数字转成用户输入数字
   *
   * @param value
   * @return {string|*}
   */
  formatApiNumber: function formatApiNumber(value) {
    if (!value) {
      return '';
    }
    return Number(value) / 100;
  },
  /**
   * 将后台保存的数字转成用户输入数字并保留小数
   *
   * @param value
   * @return {string|*}
   */
  formatApiNumberAndToFixed: function formatApiNumberAndToFixed(value) {
    if (!value) {
      return '';
    }
    var num = Number(value) / 100;
    return toFixedNumber(num);
  },
  /**
   * 格式化时间
   *
   * @param value
   * @param format
   * @return {*}
   */
  formatTime: function formatTime(value, format) {
    return parseTime(value, format);
  },
  /**
   * 格式化字符串显示
   *
   * @param value
   * @param split
   * @return {string|string|*}
   */
  formatString: function formatString(value) {
    var split = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '-';
    if (typeof value !== 'string') {
      return value;
    }
    return value || split;
  }
};