import _objectSpread from "/home/opter/repo/code/cloudsee-web/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2.js";
import _typeof from "/home/opter/repo/code/cloudsee-web/node_modules/@babel/runtime-corejs2/helpers/esm/typeof.js";
/* eslint-disable */
import { parseTime as _parseTime } from '@/utils/index';

/**
 * 弹窗 mixin
 */
export default {
  props: {
    dialogRow: {
      type: Object,
      default: null
    },
    // add|edit|detail
    dialogType: {
      type: String,
      default: null
    },
    // 额外表单参数
    dialogExtra: {
      type: Object,
      default: function _default() {
        return {};
      }
    }
  },
  data: function data() {
    return {
      loading: false
    };
  },
  computed: {
    isAdding: function isAdding() {
      return this.dialogType === 'add';
    },
    isEditing: function isEditing() {
      return this.dialogType === 'edit';
    },
    isDetail: function isDetail() {
      return this.dialogType === 'detail';
    },
    dialogTitle: function dialogTitle() {
      return this.isEditing && '编辑' || this.isDetail && '详情' || '新增';
    },
    dialogCancelText: function dialogCancelText() {
      return this.isDetail ? '关闭' : '取消';
    }
  },
  methods: {
    toggleLoading: function toggleLoading() {
      this.loading = !this.loading;
    },
    onClose: function onClose(params) {
      this.$emit('close', params);
    },
    onRefresh: function onRefresh(params) {
      this.$emit('refresh', params);
    },
    parseTime: function parseTime(val, format) {
      return _parseTime(val, format);
    },
    /**
     * 清空某个选项值
     * tips: 表单值绑定应使用 v-model="formData.xxx"
     *
     * @param params {string|Object}
     */
    resetDialogFormValue: function resetDialogFormValue(params) {
      if (!params) {
        return;
      }
      var data = {};
      if (typeof params === 'string') {
        data[params] = null;
      } else if (_typeof(params) === 'object') {
        data = _objectSpread({}, params);
      }
      this.formData = _objectSpread(_objectSpread({}, this.formData), data);
    },
    /**
     * 获取某个值
     * tips: 表单值绑定应使用 v-model="formData.xxx"
     *
     * @param key {string}
     */
    getDialogFormValue: function getDialogFormValue(key) {
      return this.formData[key];
    }
  }
};