var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-cascader", {
    ref: "cascader",
    staticClass: "full-width",
    attrs: {
      placeholder: _vm.placeholder,
      options: _vm.companyTreeData,
      props: _vm.companyTreeProps,
      "show-all-levels": _vm.showAllLevels,
      clearable: _vm.clearable,
      disabled: _vm.autoDisabled ? _vm.companyIdDisabled : _vm.selectDisabled,
    },
    on: { change: _vm.onChange },
    model: {
      value: _vm.formData.companyId,
      callback: function ($$v) {
        _vm.$set(_vm.formData, "companyId", $$v)
      },
      expression: "formData.companyId",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }